import React from "react";

const SortDecent = () => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 490 490"
      className="inline-block w-3 h-3 stroke-base-content fill-base-content"
    >
      <g>
        <polygon
          points="85.877,154.014 85.877,428.309 131.706,428.309 131.706,154.014 180.497,221.213 217.584,194.27 108.792,44.46 
  0,194.27 37.087,221.213 	"
        />
      </g>
    </svg>
  );
};

export default SortDecent;

import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router";
import ChangePassword from "./ChangePassword";
import LoadingToast from "../../assets/LoadingToast";

const parseSex = (sex) => {
  if (sex === 0) {
    return "Masculino";
  } else if (sex === 1) {
    return "Feminino";
  } else if (sex === 2) {
    return "Outro";
  }
};

const Profile = ({ logedin, token, userInfo }) => {
  const nav = useNavigate();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (logedin) {
      setLoading(true);
      fetch(`${process.env.REACT_APP_API}/api/auth/user/${userInfo.nif}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => {
          if (!response.ok) {
            if (response.status === 401) {
              nav("/login");
            } else {
              throw new Error("An error ocurred");
            }
          }
          return response.json();
        })
        .then((parsed) => {
          setUser(parsed.userInfo);
        })
        .catch((error) => {
          //TODO: Handle error
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  if (!logedin) return <Navigate replace to="/" />;
  else
    return (
      <div className="grid place-content-center w-full h-[800px]">
        {user && (
          <div className="grid grid-cols-2 gap-4 place-content-center rounded-md border-2 border-base-300 w-fit mt-2 mb-2 px-4 py-2 bg-base-200">
            <span>Nome Completo:</span>
            <span>{user.fullName}</span>
            <span>Primeiro Nome:</span>
            <span>{user.firstName}</span>
            <span>Apelido:</span>
            <span>{user.lastName}</span>
            <span>Nif:</span>
            <span>{user.nif}</span>
            <span>Número de Sócio:</span>
            <span>{user.associateNumber}</span>
            <span>Genero:</span>
            <span>{parseSex(user.sex)}</span>
          </div>
        )}
        {user && (
          <button
            className="btn btn-success w-full my-4"
            onClick={() =>
              document.getElementById("change_password_modal").showModal()
            }
          >
            Alterar Password
          </button>
        )}
        {loading && (
          <div className="flex w-full justify-center">
            <LoadingToast />
          </div>
        )}
        <ChangePassword token={token} userInfo={userInfo} />
      </div>
    );
};

export default Profile;

import React, { useRef, useState } from "react";
import LoadingToast from "../../assets/LoadingToast";
import SuccessToast from "../../assets/SuccessToast";
import ErrorToast from "../../assets/ErrorToast";
import { useNavigate } from "react-router";

const AdminEditUser = ({ token, refreshTable, users }) => {
  const nav = useNavigate();
  const fullNameInput = useRef();
  const [fullName, setFullName] = useState("");
  const firstNameInput = useRef();
  const [firstName, setFirstName] = useState("");
  const lastNameInput = useRef();
  const [lastName, setLastName] = useState("");
  const associateNumberInput = useRef();
  const [associateNumber, setAssociateNumber] = useState("");
  const [sex, setSex] = useState(null);

  const NIFInput = useRef();
  const [NIF, setNIF] = useState("");
  const [user, setUser] = useState(null);
  const [potencialUsers, setPotencialUsers] = useState([]);

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [pwChange, setPwChange] = useState(false);

  const onNIFChnage = (value) => {
    setNIF(value);
    let temp = [];
    if (value !== "")
      users.forEach((user) => {
        if (user.NIF.startsWith(value)) temp.push(user);
      });
    setPotencialUsers(temp);
  };

  const onUserSelect = (user) => {
    onNIFChnage(user.NIF);
    NIFInput.current.value = user.NIF;
    setUser(user);
    fullNameInput.current.value = user.FullName;
    setFullName(user.FullName);
    firstNameInput.current.value = user.FirstName;
    setFirstName(user.FirstName);
    lastNameInput.current.value = user.LastName;
    setLastName(user.LastName);
    associateNumberInput.current.value = user.AssociateNumber;
    setAssociateNumber(user.AssociateNumber);
    setSex(user.Sex);
  };

  const onSexChange = (value) => {
    if (value === "Male") setSex(0);
    else if (value === "Female") setSex(1);
    else if (value === "Other") setSex(2);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    setError(false);
    setSuccess(false);

    const body = {
      NIF: user.NIF,
      FullName: fullName,
      FirstName: firstName,
      LastName: lastName,
      AssociateNumber: associateNumber,
      Sex: sex,
    };

    /* Send request */
    fetch(`${process.env.REACT_APP_API}/api/auth/update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(body),
    })
      .then((result) => {
        if (result.ok) {
          setSuccess(true);
          setPwChange(false);
          refreshTable();
        } else {
          if (result.status === 401) nav("/login");
          else throw new Error("Imposivel editar o Sócio.");
        }
      })
      .catch((err) => {
        setErrorMessage(err.message);
        setError(true);
      })
      .finally((_) => {
        setLoading(false);
      });
  };

  const handlePasswordChange = () => {
    setLoading(true);
    setError(false);
    setSuccess(false);

    const body = {
      nif: NIF,
      newPw: NIF /* Set Password back to nif */,
    };

    fetch(`${process.env.REACT_APP_API}/api/auth/password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          setSuccess(true);
          setPwChange(true);
        } else if (response.status === 400) {
          throw new Error("Erro ao reiniciar a password");
        } else if (response.status === 401) nav("/login");
      })
      .catch((e) => {
        setErrorMessage(e.message);
        setError(true);
      })
      .finally((_) => {
        setLoading(false);
      });
  };

  return (
    <dialog id="edit_user_modal" className="modal">
      <div className="modal-box">
        <form method="dialog">
          <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
            ✕
          </button>
        </form>
        <form
          onSubmit={handleSubmit}
          className="grid grid-cols-1 gap-4 place-content-center w-full"
        >
          <h3 className="font-bold text-lg">Editar Sócio</h3>
          <h2 className="text-lg">NIF:</h2>
          <input
            ref={NIFInput}
            type="number"
            placeholder="NIF"
            className="input input-bordered w-full max-w-full"
            disabled={loading}
            onChange={(e) => onNIFChnage(e.target.value)}
          />
          <div className="grid grid-cols-1 gap-4 place-content-center w-full">
            {potencialUsers.length === 0 ? (
              <span>Insira um NIF</span>
            ) : (
              potencialUsers.map((user) => (
                <input
                  type="button"
                  className="btn"
                  onClick={(e) => {
                    onUserSelect(user);
                  }}
                  disabled={loading}
                  value={`${user.AssociateNumber}: ${user.FullName}`}
                />
              ))
            )}
          </div>
          {/* Edit Values */}
          <h2 className="text-lg">Nome completo:</h2>
          <input
            ref={fullNameInput}
            type="text"
            placeholder="Nome completo"
            className="input input-bordered w-full max-w-full"
            onChange={(e) => setFullName(e.target.value)}
            disabled={!user || loading}
          />
          <div className="grid grid-cols-2 gap-2 place-content-center w-full">
            <label className="text-lg">Primeiro nome:</label>
            <input
              ref={firstNameInput}
              type="text"
              placeholder="Primeiro nome"
              className="input input-bordered w-full max-w-xs"
              onChange={(e) => setFirstName(e.target.value)}
              disabled={!user || loading}
            />
            <label className="text-lg">Ultimo nome:</label>
            <input
              ref={lastNameInput}
              type="text"
              placeholder="Ultimo nome"
              className="input input-bordered w-full max-w-xs"
              onChange={(e) => setLastName(e.target.value)}
              disabled={!user || loading}
            />
            <label className="text-lg">Numero de Sócio:</label>
            <input
              ref={associateNumberInput}
              type="number"
              placeholder="Numero de Sócio"
              className="input input-bordered w-full max-w-xs"
              onChange={(e) => setAssociateNumber(e.target.value)}
              disabled={!user || loading}
            />
          </div>
          <label className="text-lg">Sexo:</label>
          <div
            className="grid grid-cols-3 w-full ml-2"
            onChange={(e) => onSexChange(e.target.value)}
          >
            <div className="flex m-auto">
              <label className="text-lg">Masculino</label>
              <input
                type="radio"
                name="radio-1"
                value="Male"
                className="radio ml-2"
                disabled={!user || loading}
                checked={sex === 0}
              />
            </div>
            <div className="flex m-auto">
              <label className="text-lg">Femenino</label>
              <input
                type="radio"
                name="radio-1"
                value="Female"
                className="radio ml-2"
                disabled={!user || loading}
                checked={sex === 1}
              />
            </div>
            <div className="flex m-auto">
              <label className="text-lg self-center">Outro</label>
              <input
                type="radio"
                name="radio-1"
                value="Other"
                className="radio ml-2"
                disabled={!user || loading}
                checked={sex === 2}
              />
            </div>
          </div>
          <input
            type="button"
            className="btn btn-error"
            value="Renovar Password"
            disabled={!user || loading}
            onClick={() => handlePasswordChange()}
          />

          <button
            type="submit"
            className="btn btn-success"
            disabled={
              !user ||
              !fullName ||
              !firstName ||
              !lastName ||
              !associateNumber ||
              !NIF ||
              loading
            }
          >
            Submeter
          </button>
          {loading && <LoadingToast />}
          {success && (
            <SuccessToast
              text={
                pwChange ? "Password Reiniciada" : "Sócio editado com sucesso"
              }
              onClick={(_) => setSuccess(false)}
            />
          )}
          {error && (
            <ErrorToast onClick={(_) => setError(false)} text={errorMessage} />
          )}
        </form>
      </div>
    </dialog>
  );
};

export default AdminEditUser;

import "./styles.css";
import ErrorToast from "../../assets/ErrorToast";

function Login({
  setNIF,
  setPassword,
  handleFormSubmit,
  showError,
  setShowError,
}) {
  return (
    <form
      className="flex flex-col items-center border-4 border-base-300 bg-base-100 login-component"
      onSubmit={handleFormSubmit}
    >
      <h1 className=" text-accent-content text-center text-xl login-text my-3">
        Log In
      </h1>
      <table className="w-full">
        <tbody>
          <tr>
            <td>
              <label className="label text-accent-content text-xl py-3">
                NIF:
              </label>
            </td>
            <td>
              <input
                type="number"
                placeholder="NIF"
                onChange={(e) => setNIF(e.target.value)}
                className="input input-bordered w-ftablel max-w-xs w-full"
              />
            </td>
          </tr>
          <tr>
            <td>
              <label className="label text-accent-content w-full text-xl py-3">
                Password:
              </label>
            </td>
            <td>
              <input
                type="password"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                className="input input-bordered w-full max-w-xs w-full"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <button type="submit" className="btn btn-success my-4">
        Log In
      </button>
      {showError && (
        <ErrorToast
          text="Ocurreu um erro. Verifique os dados inseridos."
          onClick={() => setShowError(false)}
        />
      )}
    </form>
  );
}

export default Login;

import { Navigate, useNavigate } from "react-router";

const AdminDropDown = () => {
  const navigate = useNavigate();
  return (
    <div
      className={
        "fixed bottom-[40px] right-[40px] dropdown dropdown-top dropdown-end"
      }
    >
      <label tabIndex={0} className="btn btn-circle btn-success w-19 h-19">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          className="inline-block w-10 h-10 stroke-current"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 6h16M4 12h16M4 18h16"
          ></path>
        </svg>
      </label>
      <ul
        tabIndex={0}
        className="menu dropdown-content z-[1] p-2 shadow bg-base-100 rounded-box w-52 mt-4"
      >
        <li>
          <button
            onClick={() => {
              navigate("/import");
            }}
          >
            Importar Ficheiros
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              navigate("/campains");
            }}
          >
            Campanhas
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              navigate("/socios");
            }}
          >
            Sócios
          </button>
        </li>
      </ul>
    </div>
  );
};

export default AdminDropDown;

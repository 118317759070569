import { Navigate } from "react-router";
import AdminInputFiles from "./AdminInputFiles";
import { useState } from "react";
import ResultsTable from "./ResultsTable";

const ImportFilePage = ({ token, logedin, userInfo }) => {
  const [resultData, setResultData] = useState([]);
  if (!logedin || !userInfo.adminMode) {
    return <Navigate replace to="/login" />;
  } else {
    return (
      <div className="w-full min-h-[800px]">
        <div className="grid place-content-center w-full mt-4 mb-4">
          <button
            className="btn btn-xs sm:btn-sm md:btn-md lg:btn-lg"
            onClick={() =>
              document.getElementById("insert_files_modal").showModal()
            }
          >
            Carregar ficheiro
          </button>
        </div>
        {resultData.length > 0 && <ResultsTable data={resultData} />}
        <AdminInputFiles token={token} setResultData={setResultData} />
      </div>
    );
  }
};

export default ImportFilePage;

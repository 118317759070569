import { useEffect, useRef, useState } from "react";
import Table from "./Table";
import { Navigate, useNavigate } from "react-router";
import AdminDropDown from "./AdminDropDown";
import LoadingToast from "../../assets/LoadingToast";
import DeleteTicketModal from "./DeleteTicketModal";
import SearchButton from "../../assets/SearchButton";
import SearchModal from "./SearchModal";

function Application({ token, logedin, userInfo }) {
  const nav = useNavigate();
  const [data, setData] = useState(null);
  const [total, setTotal] = useState(0);
  const [headers, setHeaders] = useState([]);

  const [statistics, setStatistics] = useState(null);

  const [loading, setLoading] = useState(false);
  const [tabs, setTabs] = useState([]);
  const [activetab, setActiveTab] = useState(null);
  const [deleteTicket, setDeleteTicket] = useState(null);

  const [nPages, setNPages] = useState(0);
  const [activePage, setAtivePage] = useState(1);
  const [pageSelect, setPageSelect] = useState(null);
  const pageSelectRef = useRef();

  const [filters, setFilters] = useState([]);

  /* Define pages */
  const setNumberofPages = (total) => {
    setNPages(Math.ceil(total / parseInt(process.env.REACT_APP_PAGE_LIMIT)));
  };

  const defineLimits = (page) => {
    const apiLim = parseInt(process.env.REACT_APP_PAGE_LIMIT);
    if (page < 1) page = 1;

    let t_skip = (page - 1) * apiLim;
    if (t_skip > total) throw new Error(`Skip overshoot: ${t_skip}/${total}`);
    if (isNaN(t_skip)) return [0, apiLim];

    return [t_skip, apiLim];
  };

  const setPage = (event) => {
    event.preventDefault();
    pageSelectRef.current.value = "";
    let page = parseInt(pageSelect);
    if (!page || page > nPages || page < 1) return;
    else setAtivePage(page);
  };

  const incrementPage = () => {
    if (activePage + 1 <= nPages) {
      setAtivePage(activePage + 1);
    }
  };

  const decrementPage = () => {
    if (activePage - 1 >= 1) {
      setAtivePage(activePage - 1);
    }
  };

  /*On tab click*/
  const handleTabClick = (tabInfo) => {
    setActiveTab(tabInfo);
    setAtivePage(1);
  };

  //* On Delete click *//
  const onDelete = (ticket) => {
    setDeleteTicket(ticket);
    document.getElementById("delete_ticket_modal").showModal();
  };

  /* filters */
  const addFilter = (filter) => {
    const property = filter.name.split("=")[0];

    filters.forEach((filter) => {
      if (filter.name.includes(property))
        throw new Error(`Cabeçalho "${property}" já se contem um filtro.`);
    });

    setFilters((filters) => [...filters, filter]);
    setAtivePage(1);
  };

  const removeFilter = (filter) => {
    setFilters((filters) =>
      filters.filter((current_filter) => {
        return current_filter.query !== filter;
      })
    );
    setAtivePage(1);
  };

  /* Table refresh */
  const refreshTable = () => {
    if (!logedin || !activetab || !userInfo || !activePage) return;

    setLoading(true);
    setData(null);
    //If NOT in admin mode, get self tickets, else, get all tickets
    let query = `${process.env.REACT_APP_API}/api/tickets/campain/${activetab.tag}/`;
    if (!userInfo.adminMode) {
      query += userInfo.associateNumber;
    }

    let [skip, limit] = defineLimits(activePage);
    query += `?skip=${skip}&limit=${limit}`; //add limits

    if (filters.length !== 0) {
      filters.forEach((filter) => {
        query += `&${filter.query}`;
      }); //add search query
    }

    //Fetch query
    fetch(query, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((result) => {
        if (!result.ok) {
          if (result.status === 401) {
            nav("/login");
          } else {
            throw new Error(`Error status: ${result.status}`);
          }
        }

        return result.json();
      })
      .then((parsed) => {
        setHeaders(parsed.headers);
        setTotal(parsed.results.total);
        setNumberofPages(parsed.results.total);
        setData(parsed.results.tickets);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    refreshTable();
  }, [activePage, activetab, filters]);

  //Triguerd once per render
  useEffect(() => {
    if (logedin) {
      /*Tabs*/
      fetch(`${process.env.REACT_APP_API}/api/campain`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then(async (response) => {
          if (response.ok) {
            let campain = await response.json();
            const fetchedTabs = [];
            campain.forEach((year) => {
              fetchedTabs.push({
                title: year.toString(),
                tag: year.toString(),
              });
            });
            setTabs(fetchedTabs);
            if (fetchedTabs.length > 0) {
              setActiveTab(fetchedTabs[0]);
            }
          } else if (response.status === 401) {
            nav("/login");
          } else {
            throw new Error("Unkown Server Error.");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  useEffect(() => {
    if (!logedin || !activetab || !userInfo) return;

    //Request Stats
    fetch(
      `${process.env.REACT_APP_API}/api/tickets/stats?campain=${activetab.tag}&associate=${userInfo.associateNumber}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    )
      .then(async (response) => {
        let parsed = await response.json();
        if (!response.ok) {
          if (response.status === 401) {
            nav("/login");
          } else {
            throw new Error(`Server Error ${parsed.results}`);
          }
        } else {
          setStatistics(parsed.results);
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, [activetab]);

  if (!logedin) {
    return <Navigate replace to="/login" />;
  } else {
    return (
      <div className="w-full min-h-[800px]">
        <div className="relative w-full">
          {tabs && (
            <div className="tabs m-4">
              {tabs.map((tab) => {
                return tab.tag === activetab.tag ? (
                  <button className="tab tab-lg tab-lifted tab-active">
                    {tab.title}
                  </button>
                ) : (
                  <button
                    className="tab tab-lg tab-lifted"
                    onClick={(_) => {
                      handleTabClick(tab);
                    }}
                  >
                    {tab.title}
                  </button>
                );
              })}
              <span class="grow border-b border-base-300"></span>
            </div>
          )}
          <div className="lg:absolute lg:inline-flex top-0 right-0 mx-4">
            {filters.map((filter) => {
              return (
                <button
                  className="btn normal-case mx-1 hover:bg-error hover:text-accent-content"
                  onClick={() => removeFilter(filter.query)}
                >
                  {filter.name}
                </button>
              );
            })}
            <SearchButton
              onClick={() => {
                document.getElementById("search_tickets_modal").showModal();
              }}
            />
          </div>
        </div>
        {/* Page content here */}
        {loading && (
          <div className="flex w-full justify-center">
            <LoadingToast />
          </div>
        )}
        {data && data?.length !== 0 ? (
          <Table
            data={data ?? []}
            stats={statistics ?? {}}
            headers={headers ?? []}
            adminMode={userInfo.adminMode}
            onDelete={onDelete}
          />
        ) : (
          !loading && (
            <span className="grid grid-cols-1 text-center max-h-[800px]">
              Sem valores para mostrar
            </span>
          )
        )}
        {nPages > 1 && !loading ? (
          <div className="grid justify-center w-full my-4">
            <div className="join">
              <button className="join-item btn" onClick={decrementPage}>
                «
              </button>
              <form onSubmit={setPage}>
                <input
                  ref={pageSelectRef}
                  type="number"
                  className="join-item btn normal-case"
                  placeholder={`Página: ${activePage}/${nPages}`}
                  onChange={(e) => setPageSelect(e.target.value)}
                  onBlur={(e) => {
                    e.target.value = "";
                  }}
                />
              </form>
              <button className="join-item btn" onClick={incrementPage}>
                »
              </button>
            </div>
          </div>
        ) : null}
        {/* Anmin button */}
        {userInfo.adminMode && <AdminDropDown />}
        {userInfo.adminMode && (
          <DeleteTicketModal
            ticket={deleteTicket}
            token={token}
            refreshTable={refreshTable}
          />
        )}
        <SearchModal headers={headers} addFilter={addFilter} />
      </div>
    );
  }
}

export default Application;

import { useState } from "react";
import "./App.css";

//React router
import { BrowserRouter, Route, Routes } from "react-router-dom";

//Cookies
import Cookies from "universal-cookie";
import jwt from "jwt-decode";

//Components
import Application from "./components/pages/application/Application";
import LoginPage from "./components/pages/login/LoginPage";
import NavBar from "./components/NavBar/NavBar";
import Footer from "./components/Footer/Footer";
import Profile from "./components/pages/profile/Profile";
import ImportFilePage from "./components/pages/ImportFile/ImportFilePage";
import Campains from "./components/pages/campains/Campains";
import UsersPage from "./components/pages/Users/UsersPage";

function App() {
  const cookies = new Cookies();

  //Initial State values
  var init_token = "";
  var init_loguedIn = false;
  var init_userInfo = {};

  const cookie = cookies.get("jwt-authorization");
  if (cookie) {
    init_token = cookie;
    init_loguedIn = true;
    init_userInfo = jwt(cookie).userInfo;
  }

  const [token, setToken] = useState(init_token);
  const [logedin, setLogedin] = useState(init_loguedIn);
  const [userInfo, setUserInfo] = useState(init_userInfo);

  //Run on User logIn
  const handleLogin = (jwtToken) => {
    //Store cookie
    const decodedToken = jwt(jwtToken);
    cookies.set("jwt-authorization", jwtToken, {
      expires: new Date(decodedToken.exp * 1000),
    });

    //Set state
    setUserInfo(decodedToken.userInfo);
    setToken(jwtToken);
    setLogedin(true);
  };

  //Run on login error
  const handleLoginError = () => {
    setLogedin(false);
  };

  //Run on User Logout
  const handleLogOut = () => {
    setToken("");
    setUserInfo({});
    setLogedin(false);
    cookies.remove("jwt-authorization");
  };

  const router = [
    {
      path: "/login",
      element: (
        <LoginPage
          handleLogin={handleLogin}
          handleLoginError={handleLoginError}
        />
      ),
    },
    {
      path: "/",
      element: (
        <Application token={token} logedin={logedin} userInfo={userInfo} />
      ),
    },
    {
      path: "/profile",
      element: <Profile token={token} logedin={logedin} userInfo={userInfo} />,
    },
    {
      path: "/import",
      element: (
        <ImportFilePage token={token} logedin={logedin} userInfo={userInfo} />
      ),
    },
    {
      path: "/campains",
      element: <Campains token={token} logedin={logedin} userInfo={userInfo} />,
    },
    {
      path: "/socios",
      element: (
        <UsersPage token={token} logedin={logedin} userInfo={userInfo} />
      ),
    },
  ];

  return (
    <BrowserRouter className="App">
      <NavBar
        logedIn={logedin}
        userInfo={userInfo}
        handleLogOut={handleLogOut}
      />
      <Routes>
        {router.map((route) => {
          return <Route path={route.path} element={route.element} />;
        })}
      </Routes>
      {/* <RouterProvider router={router}/> */}
      <Footer />
    </BrowserRouter>
  );
}

export default App;

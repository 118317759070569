import React, { useMemo } from "react";
import { useTable, useSortBy } from "react-table";
import SortDecent from "../../assets/SortDecent";
import SortAcend from "../../assets/SortAcend";
import SortNeutral from "../../assets/SortNeutral";

const parseData = (data, keys) => {
  data.forEach((d) => {
    keys.forEach((k) => {
      let value = d[k];
      if (k === "status") {
        if (value === 0) {
          value = <span className="text-info"> Incomplete </span>;
        } else if (value === 1) {
          value = <span className="text-success"> Complete </span>;
        } else if (value === 2) {
          value = <span className="text-error"> Closed </span>;
        }
      }
      /* Arrays */
      if (Array.isArray(value)) {
        value = value.join(" - ");
      }

      d[k] = value;
    });
  });
  return data;
};

const ResultsTable = ({ data }) => {
  const headers = useMemo(
    () => [
      {
        Header: "TicketNumber",
        accessor: "ticket",
      },
      {
        Header: "TicketStatus",
        accessor: "status",
      },
      {
        Header: "Missing values",
        accessor: "missing",
      },
    ],
    []
  );
  const resultData = useMemo(
    () => parseData(data, ["ticket", "status", "missing"]),
    [data]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns: headers, data: resultData }, useSortBy);

  return (
    <div className="max-h-[800px] m-4 rounded-md border-2 border-base-300 overflow-auto">
      <table className="table table-zebra">
        <thead className="sticky top-0 bg-base-300" {...getTableProps()}>
          {headerGroups.map((headerGroup) => {
            return (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  return (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      <span className="ml-1">
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <SortDecent />
                          ) : (
                            <SortAcend />
                          )
                        ) : (
                          <SortNeutral />
                        )}
                      </span>
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ResultsTable;

import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router";
import CampainsTable from "./CampainsTable";
import CreateCampainModal from "./CreateCampainModal";
import CloseCampainModal from "./CloseCampainModal";
import UpdateCampainModal from "./UpdateCampainModal";
import LoadingToast from "../../assets/LoadingToast";
import CloseCampainResultsModal from "./CloseCampainResultsModal";

const Campains = ({ logedin, token, userInfo }) => {
  const navigate = useNavigate();
  const [campains, setCampains] = useState([]);
  const [loading, setLoading] = useState(false);

  const [validationResults, setValidationResults] = useState(null);

  const refreshTable = () => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_API}/api/campain/all`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (response.status === 200) return response.json();
        else if (response.status === 401 || response.status === 404) {
          console.log(response);
          navigate("/login");
        }
      })
      .then((parsed) => {
        setCampains(parsed);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally((_) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (logedin) {
      refreshTable();
    }
  }, []);

  if (!logedin || !userInfo.adminMode) return <Navigate replace to="/" />;
  else
    return (
      <div className="w-full h-[800px]">
        <div className="grid place-content-center my-4 w-full">
          <div className="grid grid-cols-3 gap-4 place-content-center w-fit">
            <button
              className="btn"
              onClick={() =>
                document.getElementById("create_campain_modal").showModal()
              }
            >
              Criar Campanha
            </button>
            <button
              className="btn"
              onClick={() =>
                document.getElementById("close_campain_modal").showModal()
              }
            >
              Fechar Campanha
            </button>
            <button
              className="btn"
              onClick={() =>
                document.getElementById("update_campain_modal").showModal()
              }
            >
              Atualizar Campanha
            </button>
          </div>
        </div>
        {loading && (
          <div className="flex w-full justify-center">
            <LoadingToast />
          </div>
        )}
        {campains && <CampainsTable data={campains} />}
        <CreateCampainModal token={token} refreshTable={refreshTable} />
        <CloseCampainModal
          token={token}
          refreshTable={refreshTable}
          setValidationResults={setValidationResults}
        />
        <UpdateCampainModal
          token={token}
          campains={campains}
          refreshTable={refreshTable}
        />
        <CloseCampainResultsModal validationResults={validationResults} />
      </div>
    );
};

export default Campains;

import React, { useRef, useState } from "react";
import { useNavigate } from "react-router";
import LoadingToast from "../../assets/LoadingToast";
import SuccessToast from "../../assets/SuccessToast";
import ErrorToast from "../../assets/ErrorToast";

const UpdateCampainModal = ({ token, campains, refreshTable }) => {
  const navigate = useNavigate();
  const openDateInput = useRef(null);
  const closeDateInput = useRef(null);
  const campainStateInput = useRef(false);
  const [selected, setSelected] = useState(false);
  const [year, setYear] = useState(null);
  const [openDate, setOpenDate] = useState(null);
  const [closeDate, setCloseDate] = useState(null);
  const [campainState, setCampainState] = useState(null);

  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState("");

  const handleSelectChange = (choise) => {
    let value = parseInt(choise.target.value);
    let campain = null;
    campains.forEach((c) => {
      if (c.year === value) campain = c;
      return;
    });
    setYear(campain.year);
    //Set Values
    let openDate = new Date(campain.openDate).toISOString().split("T")[0];
    openDateInput.current.value = openDate;
    setOpenDate(openDate);

    let closeDate = new Date(campain.closeDate).toISOString().split("T")[0];
    closeDateInput.current.value = closeDate;
    setCloseDate(closeDate);

    setCampainState(campain.campainStatus + 1);
    setSelected(true);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    setShowToast(false);
    setShowError(false);
    try {
      //validation
      if (!openDate) {
        throw new Error("Por favor insira uma data de abertura valida");
      }

      //create form body
      const body = {
        year: year,
        openDate: openDate,
        closeDate: closeDate,
        campainStatus: parseInt(campainState) - 1,
      };

      //Submit files
      fetch(`${process.env.REACT_APP_API}/api/campain/update`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          if (response.status === 200) {
            response.json().then((parsed) => {
              setShowToast(true);
              refreshTable();
            });
          } else if (response.status === 401) {
            navigate("/login");
          } else {
            response.json().then((parsed) => {
              setError(parsed.result);
              setShowError(true);
            });
          }
        })
        .catch((error) => {
          setError(error);
          setShowError(true);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      setError(e);
      setShowToast(false);
      setShowError(true);
      setLoading(false);
    }
  };

  const setStatus = (value) => {
    if (value === "Aberta") setCampainState(1);
    else if (value === "Fechada") setCampainState(2);
  };

  return (
    <dialog id="update_campain_modal" className="modal">
      <div className="modal-box">
        <form method="dialog">
          <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
            ✕
          </button>
        </form>
        <form
          onSubmit={handleSubmit}
          className="grid grid-cols-1 gap-4 place-content-center w-full"
        >
          <h3 className="font-bold text-lg">Atualizar Campanha</h3>
          <span>Selecionar campanha</span>
          <select
            className="select select-bordered w-full max-w-xs"
            onChange={handleSelectChange}
          >
            <option disabled selected>
              Campanha
            </option>
            {campains.map((campain) => (
              <option>{campain.year}</option>
            ))}
          </select>
          <h2 className="text-lg">Data de Abertura</h2>
          <input
            ref={openDateInput}
            type="date"
            className="input input-bordered w-full max-w-xs"
            onChange={(event) => setOpenDate(event.target.value)}
            disabled={loading || !selected}
          />
          <h2 className="text-lg">Data de Fecho</h2>
          <input
            ref={closeDateInput}
            type="date"
            className="input input-bordered w-full max-w-xs"
            onChange={(event) => setCloseDate(event.target.value)}
            disabled={loading || !selected}
          />
          <div
            ref={campainStateInput}
            className="grid grid-cols-2 w-full ml-2"
            onChange={(e) => setStatus(e.target.value)}
          >
            <div className="flex m-auto">
              <label className="text-lg">Aberta</label>
              <input
                type="radio"
                name="radio-1"
                value="Aberta"
                className="radio ml-2"
                checked={campainState === 1}
                disabled={loading || !selected}
              />
            </div>
            <div className="flex m-auto">
              <label className="text-lg">Fechada</label>
              <input
                type="radio"
                name="radio-1"
                value="Fechada"
                className="radio ml-2"
                checked={campainState === 2}
                disabled={loading || !selected}
              />
            </div>
          </div>
          <button
            className="btn"
            disabled={!openDate || !campainState || !selected}
          >
            Submeter
          </button>
          {loading && <LoadingToast />}
          {showToast && (
            <SuccessToast
              text="Campanha Atualizada"
              onClick={(_) => setShowToast(false)}
            />
          )}
          {showError && (
            <ErrorToast onClick={(_) => setShowError(false)} text={error} />
          )}
        </form>
      </div>
    </dialog>
  );
};

export default UpdateCampainModal;

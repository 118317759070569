import React, { useRef, useState } from "react";
import LoadingToast from "../../assets/LoadingToast";
import SuccessToast from "../../assets/SuccessToast";
import ErrorToast from "../../assets/ErrorToast";

const ChangePassword = ({ token, userInfo }) => {
  const oldPWInput = useRef(null);
  const [oldPw, setOldPw] = useState("");
  const newPwInput = useRef(null);
  const [newPw, setNewPw] = useState("");
  const newPWConfirmInput = useRef(null);
  const [newPWConfirm, setNewPwConfirm] = useState("");
  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      setShowToast(false);
      setShowError(false);

      if (newPw !== newPWConfirm)
        throw new Error("Password confirmada é diferente da password inserida");

      const body = {
        nif: userInfo.nif,
        oldPw: oldPw,
        newPw: newPw,
      };

      fetch(`${process.env.REACT_APP_API}/api/auth/password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          if (response.status === 200) {
            setShowToast(true);
          } else if (response.status === 400) {
            throw new Error("Password Invalida");
          } else {
            throw new Error("Erro Desconhecido");
          }
        })
        .catch((e) => {
          setError(e.message);
          setShowError(true);
        });
    } catch (e) {
      setError(e.message);
      setShowError(true);
    } finally {
      setLoading(false);
      oldPWInput.current.value = "";
      newPwInput.current.value = "";
      newPWConfirmInput.current.value = "";
    }
  };

  return (
    <dialog id="change_password_modal" className="modal">
      <div className="modal-box">
        <form method="dialog">
          <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
            ✕
          </button>
        </form>
        <form
          onSubmit={handleSubmit}
          className="grid grid-cols-1 gap-4 place-content-center w-full"
        >
          <h3 className="font-bold text-lg">Alterar Password</h3>
          <h2 className="text-lg">Password antiga</h2>
          <input
            ref={oldPWInput}
            type="password"
            className="file-input file-input-bordered justify-self-center w-full"
            onChange={(event) => setOldPw(event.target.value)}
            disabled={loading}
          />
          <h2 className="text-lg">Password Nova</h2>
          <input
            ref={newPwInput}
            type="password"
            className="file-input file-input-bordered justify-self-center w-full"
            onChange={(event) => setNewPw(event.target.value)}
            disabled={loading}
          />
          <h2 className="text-lg">Confirmar Password Nova</h2>
          <input
            ref={newPWConfirmInput}
            type="password"
            className="file-input file-input-bordered justify-self-center w-full"
            onChange={(event) => setNewPwConfirm(event.target.value)}
            disabled={loading}
          />
          <button className="btn">Alterar</button>
          {loading && <LoadingToast />}
          {showToast && (
            <SuccessToast
              onClick={(_) => setShowToast(false)}
              text="Password alterada com sucesso."
            />
          )}
          {showError && (
            <ErrorToast onClick={(_) => setShowError(false)} text={error} />
          )}
        </form>
      </div>
    </dialog>
  );
};

export default ChangePassword;

import React, { useState } from "react";
import LoadingToast from "../../assets/LoadingToast";
import SuccessToast from "../../assets/SuccessToast";
import ErrorToast from "../../assets/ErrorToast";
import { useNavigate } from "react-router";

const DeleteTicketModal = ({ ticket, token, refreshTable }) => {
  const nav = useNavigate();
  const [showSuccess, setSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  /* On confirm deletion */
  const onConfirmDelete = () => {
    setLoading(true);
    setShowError(false);
    setSuccess(false);
    const body = {
      ticketNumber: ticket.TicketNumber,
    };
    fetch(`${process.env.REACT_APP_API}/api/tickets/delete`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(body),
    })
      .then((result) => {
        if (!result.ok) {
          if (result.status === 401) {
            nav("/login");
          } else {
            throw new Error("Erro de servidor - Talão não apagado.");
          }
        }
        return result.json();
      })
      .then((parsed) => {
        const result = parsed.results;
        if (result) {
          setSuccess(true);
          refreshTable();
        } else {
          setErrorMessage("Talão não encontrado.");
          setShowError(true);
        }
      })
      .catch((err) => {
        setErrorMessage(err.message);
        setShowError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <dialog id="delete_ticket_modal" className="modal">
      <div className="modal-box">
        <form method="dialog">
          <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
            ✕
          </button>
        </form>
        <form className="grid grid-cols-1 gap-4 place-content-center w-full">
          <h3 className="font-bold text-lg">Apagar Talão</h3>
          <span>
            Tem a certeza que pertende apagar o talão numero:
            {ticket?.TicketNumber}
          </span>
          <input
            type="button"
            className="btn"
            value="Confirmo"
            onClick={() => onConfirmDelete(ticket)}
          />
          <input
            type="button"
            className="btn"
            value="Cancelar"
            onClick={() =>
              document.getElementById("delete_ticket_modal").close()
            }
          />
          {loading && <LoadingToast />}
          {showSuccess && (
            <SuccessToast
              text="Talão apagado com successo."
              onClick={() => setSuccess(false)}
            />
          )}
          {showError && (
            <ErrorToast
              text={errorMessage}
              onClick={() => setShowError(false)}
            />
          )}
        </form>
      </div>
    </dialog>
  );
};

export default DeleteTicketModal;

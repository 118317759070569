import React, { useRef, useState } from "react";
import LoadingToast from "../../assets/LoadingToast";
import SuccessToast from "../../assets/SuccessToast";
import ErrorToast from "../../assets/ErrorToast";
import { useNavigate } from "react-router";

const AdminDeleteUser = ({ token, refreshTable, users }) => {
  const nav = useNavigate();
  const NIFInput = useRef();
  const [user, setUser] = useState(null);
  const [potencialUsers, setPotencialUsers] = useState([]);

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const onNIFChnage = (value) => {
    let user_value = null;
    let temp = [];
    if (value !== "") {
      users.forEach((user) => {
        if (user.NIF.startsWith(value)) temp.push(user);
        if (user.NIF === value) user_value = user;
      });
    }
    setUser(user_value);
    setPotencialUsers(temp);
  };

  const onUserSelect = (user) => {
    onNIFChnage(user.NIF);
    NIFInput.current.value = user.NIF;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    setError(false);
    setSuccess(false);

    const body = {
      nif: user.NIF,
    };

    /* Send request */
    fetch(`${process.env.REACT_APP_API}/api/auth/delete`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(body),
    })
      .then((result) => {
        if (result.ok) {
          setSuccess(true);
          refreshTable();
        } else {
          if (result.status === 401) nav("/login");
          else throw new Error("Não foi possivel remover o sócio");
        }
      })
      .catch((err) => {
        setErrorMessage(err.message);
        setError(true);
      })
      .finally((_) => {
        setLoading(false);
      });
  };

  return (
    <dialog id="delete_user_modal" className="modal">
      <div className="modal-box">
        <form method="dialog">
          <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
            ✕
          </button>
        </form>
        <form
          onSubmit={handleSubmit}
          className="grid grid-cols-1 gap-4 place-content-center w-full"
        >
          <h3 className="font-bold text-lg">Eliminar Sócio</h3>
          <h2 className="text-lg">NIF:</h2>
          <input
            ref={NIFInput}
            type="number"
            placeholder="NIF"
            className="input input-bordered w-full max-w-full"
            onChange={(e) => onNIFChnage(e.target.value)}
            disabled={loading}
          />
          <div className="grid grid-cols-1 gap-4 place-content-center w-full">
            {potencialUsers.length === 0 ? (
              <span>Insira um NIF</span>
            ) : (
              potencialUsers.map((user) => (
                <input
                  className="btn"
                  type="button"
                  onClick={() => onUserSelect(user)}
                  value={`${user.AssociateNumber}: ${user.FullName}`}
                  disabled={loading}
                />
              ))
            )}
          </div>
          <button
            className="btn btn-success"
            type="submit"
            disabled={!user || loading}
          >
            Submeter
          </button>
          {loading && <LoadingToast />}
          {success && (
            <SuccessToast
              text="Sócio Removido"
              onClick={(_) => setSuccess(false)}
            />
          )}
          {error && (
            <ErrorToast onClick={(_) => setError(false)} text={errorMessage} />
          )}
        </form>
      </div>
    </dialog>
  );
};

export default AdminDeleteUser;

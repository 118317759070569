import React, { useEffect, useState, useMemo } from "react";
import { useTable, useSortBy } from "react-table";
import SortDecent from "../../assets/SortDecent";
import SortAcend from "../../assets/SortAcend";
import SortNeutral from "../../assets/SortNeutral";
import SuccessToast from "../../assets/SuccessToast";
import ErrorToast from "../../assets/ErrorToast";

const CloseCampainResultsModal = ({ validationResults }) => {
  const [showToast, setShowToast] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    setShowError(false);
    setShowToast(false);
    if (validationResults?.valid) {
      setShowToast(true);
    } else {
      setShowError(true);
    }
  }, [validationResults]);

  const headers = useMemo(
    () => [
      {
        Header: "Talão",
        accessor: "ticket",
      },
      {
        Header: "Mensagem",
        accessor: "msg",
      },
    ],
    [validationResults]
  );
  const data = useMemo(
    () => validationResults?.results || [],
    [validationResults]
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns: headers, data: data }, useSortBy);

  return (
    <dialog id="close_campain_results_modal" className="modal">
      <div className="modal-box max-w-6xl max-h-screen my-6">
        <form method="dialog">
          <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
            ✕
          </button>
        </form>
        <form className="grid grid-cols-1 gap-4 place-content-center w-full h-full">
          <h3 className="font-bold text-lg">Fecho da Campanha: Resultados</h3>
          {showToast && (
            <SuccessToast
              onClick={(_) => setShowToast(false)}
              text="Campnha fechada com sucesso."
            />
          )}
          {showError && (
            <ErrorToast
              onClick={(_) => setShowError(false)}
              text={"Alguns talões falharam o processo de validação."}
            />
          )}
          {validationResults?.results.length !== 0 && (
            <div className="inline-block align-top overflow-y-scroll max-h-[700px]">
              <table className="table table-zebra">
                <thead
                  className="sticky top-0 bg-base-300"
                  {...getTableProps()}
                >
                  {headerGroups.map((headerGroup) => {
                    return (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => {
                          return (
                            <th
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                            >
                              {column.render("Header")}
                              <span className="ml-1">
                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <SortDecent />
                                  ) : (
                                    <SortAcend />
                                  )
                                ) : (
                                  <SortNeutral />
                                )}
                              </span>
                            </th>
                          );
                        })}
                      </tr>
                    );
                  })}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
          <input
            type="button"
            value="Fechar"
            className="btn"
            onClick={() =>
              document.getElementById("close_campain_results_modal").close()
            }
          />
        </form>
      </div>
    </dialog>
  );
};

export default CloseCampainResultsModal;

import React from "react";

const ErrorToast = ({ text, onClick }) => {
  return (
    <div className="alert alert-error" onClick={() => onClick()}>
      <span>{text}</span>
    </div>
  );
};

export default ErrorToast;

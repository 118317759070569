import { useState } from "react";
import Login from "./Login";
import "./styles.css";
import image from "./login-background.png";
import { useNavigate } from "react-router";

const LoginPage = ({ handleLogin, handleLoginError }) => {
  const navigate = useNavigate();

  const [nif, setNIF] = useState("");
  const [password, setPassword] = useState("");
  const [showError, setShowError] = useState(false);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const credentials = {
      nif: nif,
      password: password,
    };

    fetch(`${process.env.REACT_APP_API}/api/auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(credentials),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401) navigate("/login");
          else throw new Error("Unkonw Server Error.");
        }
      })
      .then((response) => {
        handleLogin(response.token);
        navigate("/");
      })
      .catch((error) => {
        setShowError(true);
        handleLoginError();
      });
  };

  return (
    <div
      className="flex w-full h-[800px] bg-auto 2xl:bg-cover"
      style={{ backgroundImage: `url(${image})` }}
    >
      <Login
        setNIF={setNIF}
        setPassword={setPassword}
        handleFormSubmit={handleFormSubmit}
        showError={showError}
        setShowError={setShowError}
      />
    </div>
  );
};

export default LoginPage;

import React, { useRef, useState } from "react";
import { useNavigate } from "react-router";
import LoadingToast from "../../assets/LoadingToast";
import SuccessToast from "../../assets/SuccessToast";
import ErrorToast from "../../assets/ErrorToast";

const CreateCampainModal = ({ token, refreshTable }) => {
  const navigate = useNavigate();
  const openDateInput = useRef(null);
  const [openDate, setOpenDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    setShowToast(false);
    setShowError(false);

    //validation
    if (!openDate) {
      setError("Por favor insira uma data valida");
      setShowToast(false);
      setShowError(true);
      setLoading(false);
      return;
    }

    //create form body
    const body = {
      date: Date.parse(openDate),
    };

    //Submit files
    fetch(`${process.env.REACT_APP_API}/api/campain/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((parsed) => {
            setShowToast(true);
            refreshTable();
          });
        } else if (response.status === 500) {
          response.json().then((parsed) => {
            setError(parsed.result);
            setShowError(true);
          });
        } else if (response.status === 401) {
          navigate("/login");
        }
      })
      .catch((error) => {
        setError(error);
        setShowError(true);
      })
      .finally(() => {
        openDateInput.current.value = "";
        setOpenDate(null);
        setLoading(false);
      });
  };

  return (
    <dialog id="create_campain_modal" className="modal">
      <div className="modal-box">
        <form method="dialog">
          <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
            ✕
          </button>
        </form>
        <form
          onSubmit={handleSubmit}
          className="grid grid-cols-1 gap-4 place-content-center w-full"
        >
          <h3 className="font-bold text-lg">Criar Campanha</h3>
          <h2 className="text-lg">Data de Abertura</h2>
          <input
            ref={openDateInput}
            type="date"
            className="input input-bordered w-full max-w-xs"
            onChange={(event) => setOpenDate(event.target.value)}
            disabled={loading}
          />
          <button className="btn" disabled={!openDate}>
            Submeter
          </button>
          {loading && <LoadingToast />}
          {showToast && (
            <SuccessToast
              onClick={(_) => setShowToast(false)}
              text="Campanha Criada"
            />
          )}
          {showError && (
            <ErrorToast onClick={(_) => setShowError(false)} text={error} />
          )}
        </form>
      </div>
    </dialog>
  );
};

export default CreateCampainModal;

import { Navigate, useNavigate } from "react-router";
import { useEffect, useState } from "react";
import UsersTable from "./UsersTable";
import AdminCreateUser from "./AdminCreateUser";
import AdminDeleteUser from "./AdminDeleteUser";
import AdminEditUser from "./AdminEditUser";
import LoadingToast from "../../assets/LoadingToast";

const UsersPage = ({ token, logedin, userInfo }) => {
  const nav = useNavigate();
  const [loading, setLoading] = useState(false);
  const [resultData, setResultData] = useState([]);

  const refreshTable = () => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_API}/api/auth/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 401) nav("/login");
          else throw new Error("An error occured");
        }
        return response.json();
      })
      .then((parsed) => {
        setResultData(parsed);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    refreshTable();
  }, []);

  if (!logedin || !userInfo.adminMode) {
    return <Navigate replace to="/login" />;
  } else {
    return (
      <div className="w-full min-h-[800px]">
        <div className="grid place-content-center w-full mt-4 mb-4">
          <div className="grid grid-cols-3 gap-4 place-content-center w-fit">
            <button
              className="btn btn-xs sm:btn-sm md:btn-md lg:btn-lg"
              onClick={() =>
                document.getElementById("create_user_modal").showModal()
              }
            >
              Adicionar Sócio
            </button>
            <button
              className="btn btn-xs sm:btn-sm md:btn-md lg:btn-lg"
              onClick={() =>
                document.getElementById("delete_user_modal").showModal()
              }
            >
              Remover Sócio
            </button>
            <button
              className="btn btn-xs sm:btn-sm md:btn-md lg:btn-lg"
              onClick={() =>
                document.getElementById("edit_user_modal").showModal()
              }
            >
              Editar Sócio
            </button>
          </div>
        </div>
        {loading && (
          <div className="flex w-full justify-center">
            <LoadingToast />
          </div>
        )}
        {resultData.length > 0 && <UsersTable data={resultData} />}
        <AdminCreateUser token={token} refreshTable={refreshTable} />
        <AdminDeleteUser
          token={token}
          refreshTable={refreshTable}
          users={resultData}
        />
        <AdminEditUser
          token={token}
          refreshTable={refreshTable}
          users={resultData}
        />
      </div>
    );
  }
};

export default UsersPage;

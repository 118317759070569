import React, { useMemo, useRef, useState } from "react";
import ErrorToast from "../../assets/ErrorToast";
import parseName from "../../../hooks/parse_table_names";

const SearchModal = ({ headers, addFilter }) => {
  const headerInput = useRef();
  const [header, setHeader] = useState("");
  const valueInput = useRef();
  const [value, setValue] = useState("");
  const [dateValue, setDateValue] = useState(false);

  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const onHeaderSelect = (event) => {
    if (event.target.value === "Data") setDateValue(true);
    else setDateValue(false);

    setHeader(event.target.value);
  };

  const onValueInput = (event) => {
    setValue(event.target.value);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setShowError(false);
    setErrorMessage("");
    try {
      if (header === "Cabeçalho" || header === "" || value === "") {
        throw new Error("Parâmetros de pesquisa inválidos.");
      }

      let defval = value;
      if (dateValue) {
        defval = Date.parse(value);
        if (defval === "Invalid Date") throw new Error("Data invalida.");
      }

      const query = `${header}=${defval}`;
      addFilter({
        name: `${parseName(header) || header} = ${value}`,
        query: query,
      });

      setHeader("");
      headerInput.current.value = "Cabeçalho";
      valueInput.current.value = "";
      setValue("");
      setDateValue(false);
      document.getElementById("search_tickets_modal").close();
    } catch (e) {
      setShowError(true);
      setErrorMessage(e.message);
    }
  };

  return (
    <dialog id="search_tickets_modal" className="modal">
      <div className="modal-box">
        <form method="dialog">
          <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
            ✕
          </button>
        </form>
        <form
          className="grid grid-cols-1 gap-4 place-content-center w-full"
          onSubmit={onSubmit}
        >
          <h3 className="font-bold text-lg">Porcurar Talões</h3>

          <div className="grid grid-cols-2 gap-2 w-full">
            <span>Selecionar Cabeçalho:</span>
            <span>Selecionar valor:</span>
            <select
              ref={headerInput}
              className="select select-bordered w-full max-w-xs"
              onChange={onHeaderSelect}
            >
              <option disabled selected>
                Cabeçalho
              </option>
              {headers?.map((header) => {
                return (
                  <option value={header}>{parseName(header) || header}</option>
                );
              })}
            </select>
            {!dateValue ? (
              <input
                ref={valueInput}
                type="text"
                placeholder="Valor"
                className="input input-bordered w-full max-w-xs"
                onChange={onValueInput}
              />
            ) : (
              <input
                ref={valueInput}
                type="date"
                placeholder="Valor"
                className="input input-bordered w-full max-w-xs"
                onChange={onValueInput}
              />
            )}
          </div>

          <button type="submit" className="btn w-full normal-case">
            Pesquisar
          </button>
          {showError && (
            <ErrorToast
              text={errorMessage}
              onClick={() => setShowError(false)}
            />
          )}
        </form>
      </div>
    </dialog>
  );
};

export default SearchModal;

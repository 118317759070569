import React from "react";

const SuccessToast = ({ text, onClick }) => {
  return (
    <div className="alert alert-success" onClick={() => onClick()}>
      <span>{text}</span>
    </div>
  );
};

export default SuccessToast;

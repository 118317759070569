import React, { useRef, useState } from "react";
import { useNavigate } from "react-router";
import LoadingToast from "../../assets/LoadingToast";
import SuccessToast from "../../assets/SuccessToast";
import ErrorToast from "../../assets/ErrorToast";

const CloseCampainModal = ({ token, refreshTable, setValidationResults }) => {
  const navigate = useNavigate();
  const closeDateInput = useRef(null);
  const [closeDate, setCloseDate] = useState(null);
  const [confirmClose, setConfirmClose] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    setShowToast(false);
    setShowError(false);

    //validation
    if (!closeDate) {
      setError("Por favor insira uma data valida");
      setShowToast(false);
      setShowError(true);
      setLoading(false);
      setConfirmClose(false);
      return;
    }

    if (!confirmClose) {
      setError("Por favor confirme o feicho da campanha");
      setShowToast(false);
      setShowError(true);
      setLoading(false);
      setConfirmClose(false);
      return;
    }

    //create form body
    const body = {
      date: Date.parse(closeDate),
    };

    //Submit files
    fetch(`${process.env.REACT_APP_API}/api/campain/close`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((parsed) => {
            setShowToast(true);
            refreshTable();
            setValidationResults(parsed.results);
            document.getElementById("close_campain_modal").close();
            document.getElementById("close_campain_results_modal").showModal();
          });
        } else if (response.status === 401) {
          navigate("/login");
        } else {
          response.json().then((parsed) => {
            setError(parsed.results || parsed.result);
            setShowError(true);
          });
        }
      })
      .catch((error) => {
        setError(error);
        setShowError(true);
      })
      .finally(() => {
        closeDateInput.current.value = "";
        setCloseDate(null);
        setLoading(false);
        setConfirmClose(false);
      });
  };

  return (
    <dialog id="close_campain_modal" className="modal">
      <div className="modal-box">
        <form method="dialog">
          <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
            ✕
          </button>
        </form>
        <form
          onSubmit={handleSubmit}
          className="grid grid-cols-1 gap-4 place-content-center w-full"
        >
          <h3 className="font-bold text-lg">Fechar Campanha</h3>
          <h2 className="text-lg">Data de Fecho</h2>
          <input
            ref={closeDateInput}
            type="date"
            className="input input-bordered w-full max-w-xs"
            onChange={(event) => setCloseDate(event.target.value)}
            disabled={loading}
          />
          <div className="form-control">
            <label className="label cursor-pointer">
              <span className="label-text">
                Confimo que pertendo fechar a campanha.
              </span>
              <input
                type="checkbox"
                checked={confirmClose}
                className="checkbox"
                onChange={(e) => setConfirmClose(e.target.checked)}
                disabled={loading}
              />
            </label>
          </div>
          <button className="btn" disabled={!confirmClose || !closeDate}>
            Submeter
          </button>
          {loading && <LoadingToast />}
          {showToast && (
            <SuccessToast
              onClick={(_) => setShowToast(false)}
              text="Campnha Fechada."
            />
          )}
          {showError && (
            <ErrorToast onClick={(_) => setShowError(false)} text={error} />
          )}
        </form>
      </div>
    </dialog>
  );
};

export default CloseCampainModal;

const dictionary = {
  Data: "Data Entrega",
  TicketNumber: "Talão",
  Variedade: "Variedade",
  KilosBruto: "Peso",
  GMS: "GMS(%)",
  Classificacao: "Classe",
  Acidez: "Acidez",
  Humidade: "Humid (%)",
  Rendimento: "Rend. Ind. (%)",
  PesoTotal: "Total (kg)",
  PesoLagar: "Lagar (kg)",
  PesoConserva: "Cnsrva (kg)",
};

const parseName = (name) => {
  if (dictionary[name]) return dictionary[name];
  else return null;
};

module.exports = parseName;

import React, { useState } from "react";
import LoadingToast from "../../assets/LoadingToast";
import SuccessToast from "../../assets/SuccessToast";
import ErrorToast from "../../assets/ErrorToast";
import { useNavigate } from "react-router";

const AdminCreateUser = ({ token, refreshTable }) => {
  const nav = useNavigate();
  const [fullName, setFullName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [nif, setNIF] = useState("");
  const [associateNumber, setAssociateNumber] = useState("");
  const [sex, setSex] = useState("");

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    setError(false);
    setSuccess(false);

    /* Validate */
    try {
      const num_nif = Number(nif);
      if (num_nif <= 0) throw new Error("NIF NaN");

      var num_sex = -1;
      if (sex === "Male") num_sex = 0;
      else if (sex === "Female") num_sex = 1;
      else if (sex === "Other") num_sex = 3;
      else throw new Error("Invalid Sex");

      var num_associate = -1;
      num_associate = Number(associateNumber);

      /* Create Body */
      const user = {
        FirstName: firstName,
        LastName: lastName,
        FullName: fullName,
        NIF: nif,
        Password: nif.toString() /* Temporary */,
        Sex: num_sex,
        AssociateNumber: num_associate,
      };

      /* Send request */
      fetch(`${process.env.REACT_APP_API}/api/auth/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(user),
      })
        .then((result) => {
          if (result.ok) {
            refreshTable();
            setSuccess(true);
          } else {
            if (result.status === 401) nav("/login");
            else throw new Error("Imposivel criar sócio com estes dados.");
          }
        })
        .catch((error) => {
          setErrorMessage(error.message);
          setError(true);
        })
        .finally((_) => {
          setLoading(false);
        });
    } catch (error) {
      setErrorMessage(error.message);
      setError(true);
      setLoading(false);
    }
  };

  return (
    <dialog id="create_user_modal" className="modal">
      <div className="modal-box">
        <form method="dialog">
          <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
            ✕
          </button>
        </form>
        <form
          onSubmit={handleSubmit}
          className="grid grid-cols-1 gap-4 place-content-center w-full"
        >
          <h3 className="font-bold text-lg">Registar Sócio</h3>
          <h2 className="text-lg">Nome completo:</h2>
          <input
            type="text"
            placeholder="Nome completo"
            className="input input-bordered w-full max-w-full"
            onChange={(e) => setFullName(e.target.value)}
            disabled={loading}
          />
          <div className="grid grid-cols-2 gap-2 place-content-center w-full">
            <label className="text-lg">Primeiro nome:</label>
            <input
              type="text"
              placeholder="Primeiro nome"
              className="input input-bordered w-full max-w-xs"
              onChange={(e) => setFirstName(e.target.value)}
              disabled={loading}
            />
            <label className="text-lg">Ultimo nome:</label>
            <input
              type="text"
              placeholder="Ultimo nome"
              className="input input-bordered w-full max-w-xs"
              onChange={(e) => setLastName(e.target.value)}
              disabled={loading}
            />
            <label className="text-lg">NIF:</label>
            <input
              type="number"
              placeholder="NIF"
              className="input input-bordered w-full max-w-xs"
              onChange={(e) => setNIF(e.target.value)}
              disabled={loading}
            />
            <label className="text-lg">Numero de Sócio:</label>
            <input
              type="number"
              placeholder="Numero de Sócio"
              className="input input-bordered w-full max-w-xs"
              onChange={(e) => setAssociateNumber(e.target.value)}
              disabled={loading}
            />
          </div>
          <label className="text-lg">Sexo:</label>
          <div
            className="grid grid-cols-3 w-full ml-2"
            onChange={(e) => setSex(e.target.value)}
          >
            <div className="flex m-auto">
              <label className="text-lg">Masculino</label>
              <input
                type="radio"
                name="radio-1"
                value="Male"
                className="radio ml-2"
                disabled={loading}
              />
            </div>
            <div className="flex m-auto">
              <label className="text-lg">Femenino</label>
              <input
                type="radio"
                name="radio-1"
                value="Female"
                className="radio ml-2"
                disabled={loading}
              />
            </div>
            <div className="flex m-auto">
              <label className="text-lg self-center">Outro</label>
              <input
                type="radio"
                name="radio-1"
                value="Other"
                className="radio ml-2"
                disabled={loading}
              />
            </div>
          </div>
          <button className="btn" disabled={loading}>
            Submeter
          </button>
          {loading && <LoadingToast />}
          {success && (
            <SuccessToast
              text="Sócio Criado"
              onClick={(_) => setSuccess(false)}
            />
          )}
          {error && (
            <ErrorToast onClick={(_) => setError(false)} text={errorMessage} />
          )}
        </form>
      </div>
    </dialog>
  );
};

export default AdminCreateUser;

import React, { useMemo } from "react";
import { useTable, useSortBy } from "react-table";
import SortDecent from "../../assets/SortDecent";
import SortAcend from "../../assets/SortAcend";
import SortNeutral from "../../assets/SortNeutral";
const parseHeader = require("../../../hooks/parse_table_names");

const buildHeaders = (headers, adminMode, onDelete) => {
  let hlist = [];

  //* Delete for admins *//
  if (adminMode && headers.length !== 0)
    hlist.push({
      Header: "Apagar",
      Cell: (tableInstance) => (
        <button
          className="btn btn-error"
          onClick={() => onDelete(tableInstance.row.original)}
        >
          Apagar Talão
        </button>
      ),
    });

  headers.forEach((header) => {
    hlist.push({
      Header: parseHeader(header) || header,
      accessor: header,
    });
  });
  return hlist;
};

/* Checks if a given string matches a timestamp */
const checkDate = (date) => {
  const utcRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
  return utcRegex.test(date);
};

/* Day name parser */
const dateParser = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"];

const parseValues = (value, key) => {
  //TicketStatus
  if (key === "TicketStatus") {
    if (value === 0) {
      return <span className="text-info"> Incomplete </span>;
    } else if (value === 1) {
      return <span className="text-success"> Complete </span>;
    } else if (value === 2) {
      return <span className="text-error"> Closed </span>;
    }
  }

  //Strings
  if (typeof value === "string") {
    if (checkDate(value)) {
      let date = new Date(value);
      return `${dateParser[date.getDay()]} ${date.getUTCDate()}/${
        date.getUTCMonth() + 1
      }/${date.getUTCFullYear()}`;
      //return date.toDateString();
    }
  }
  return value;
};

const parseData = (data, headers) => {
  data.forEach((d) => {
    headers.forEach((h) => {
      d[h] = parseValues(d[h], h);
    });
  });
  return data;
};

const headerTotals = {
  Rendimento: "avgRendimento",
  PesoTotal: "totalPeso",
  PesoLagar: "totalLagar",
  PesoConserva: "totalConserva",
  GMS: "avgGMS",
  Acidez: "avgAcidez",
  Humidade: "avgHumidade",
};

const defineStats = (stats, headers) => {
  return headers.map((header) => {
    let th = headerTotals[header];
    if (!th) {
      return <td></td>;
    } else {
      return (
        <td>{`${stats[th]?.toLocaleString("pt-PT", {
          maximumFractionDigits: 1,
        })}`}</td>
      );
    }
  });
};

/** Component **/
const Table = ({ data, stats, headers, adminMode, onDelete }) => {
  const tableData = useMemo(() => parseData(data, headers), [data, headers]);
  const tableheaders = useMemo(
    () => buildHeaders(headers, adminMode, onDelete),
    [headers]
  );

  /* Build table */
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns: tableheaders, data: tableData }, useSortBy);

  return (
    <div className="max-h-[650px] m-4 rounded-md border-2 border-base-300 overflow-auto">
      <table className="table table-zebra text-center">
        <thead className="sticky top-0 bg-base-300" {...getTableProps()}>
          {headerGroups.map((headerGroup) => {
            return (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  return (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      <span className="ml-1">
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <SortDecent />
                          ) : (
                            <SortAcend />
                          )
                        ) : (
                          <SortNeutral />
                        )}
                      </span>
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td className="whitespace-nowrap" {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
          <tr className="sticky bottom-0 !bg-base-300">
            {
              /*Add Empty if admin due to the Delete column*/
              adminMode && <td></td>
            }

            {/*Add totals by column order*/ defineStats(stats, headers)}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Table;

import React, { useMemo } from "react";
import { useTable, useSortBy } from "react-table";
import SortDecent from "../../assets/SortDecent";
import SortAcend from "../../assets/SortAcend";
import SortNeutral from "../../assets/SortNeutral";

const parseData = (data, keys) => {
  const temp = [];
  data.forEach((d) => {
    let res = {};
    keys.forEach((k) => {
      let value = d[k];
      if (k === "Sex") {
        if (value === 0) {
          value = " Masculino";
        } else if (value === 1) {
          value = "Feminino";
        } else if (value === 2) {
          value = "Outro";
        }
      }
      res[k] = value;
    });
    temp.push(res);
  });
  return temp;
};

const UsersTable = ({ data }) => {
  const headers = useMemo(
    () => [
      {
        Header: "Primeiro Nome",
        accessor: "FirstName",
      },
      {
        Header: "Ultimo Nome",
        accessor: "LastName",
      },
      {
        Header: "Nome Completo",
        accessor: "FullName",
      },
      {
        Header: "NIF",
        accessor: "NIF",
      },
      {
        Header: "Sexo",
        accessor: "Sex",
      },
      {
        Header: "Numero de Sócio",
        accessor: "AssociateNumber",
      },
    ],
    []
  );
  const resultData = useMemo(
    () =>
      parseData(data, [
        "FirstName",
        "LastName",
        "FullName",
        "NIF",
        "Sex",
        "AssociateNumber",
      ]),
    [data]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns: headers, data: resultData }, useSortBy);

  return (
    <div className="max-h-[800px] m-4 rounded-md border-2 border-base-300 overflow-auto">
      <table className="table table-zebra">
        <thead className="sticky top-0 bg-base-300" {...getTableProps()}>
          {headerGroups.map((headerGroup) => {
            return (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  return (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      <span className="ml-1">
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <SortDecent />
                          ) : (
                            <SortAcend />
                          )
                        ) : (
                          <SortNeutral />
                        )}
                      </span>
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default UsersTable;

import React from "react";
import { Link } from "react-router-dom";

import "./styles.css";
import logo from "./logo.png";

const DropButton = ({ userInfo, handleLogOut, long }) => {
  //
  let label = (
    <label tabIndex={0} className="btn btn-ghost rounded-btn normal-case">
      {userInfo.name}, Sócio Nº{userInfo.associateNumber}
    </label>
  );

  if (!long)
    label = (
      <label tabIndex={0} className="btn btn-square btn-ghost">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          className="inline-block w-6 h-6 stroke-current"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 6h16M4 12h16M4 18h16"
          ></path>
        </svg>
      </label>
    );
  return (
    <div className={`dropdown dropdown-bottom ${long && "dropdown-end"}`}>
      {label}
      <ul
        tabIndex={0}
        className="menu dropdown-content z-[1] p-2 shadow bg-base-100 rounded-box w-52 mt-4"
      >
        <li>
          <Link to="/profile">
            {long ? (
              `Perfil`
            ) : (
              <div>
                <div className="truncate"> {userInfo.name} </div>
                <div> Sócio Nº{userInfo.associateNumber} </div>
              </div>
            )}
          </Link>
        </li>
        <li>
          <Link to="/">Talões</Link>
        </li>
        <li>
          <a onClick={handleLogOut}>Sair</a>
        </li>
      </ul>
    </div>
  );
};

const NavBar = ({ logedIn, userInfo, handleLogOut }) => {
  return (
    <div className="w-full navbar bg-base-300 nav-bar">
      {logedIn && (
        <div className="lg:hidden">
          <DropButton
            userInfo={userInfo}
            handleLogOut={handleLogOut}
            long={false}
          />
        </div>
      )}
      <Link to="/">
        <img className="nav-bar-logo" alt="caoc-logo" src={logo} />
      </Link>
      <h1 className="hidden lg:flex lg:flex-1 px-2 mx-2 text-accent-content nav-bar-title">
        Cooperativa Agrícola dos Olivicultores de Cano, CRL
      </h1>
      <h1 className="flex-1 lg:hidden px-2 mx-2 text-accent-content nav-bar-title">
        C.A.O.C., CRL
      </h1>
      {logedIn && (
        <div className="flex-none hidden lg:block">
          <ul className="menu menu-horizontal">
            <li>
              <DropButton
                userInfo={userInfo}
                handleLogOut={handleLogOut}
                long={true}
              />
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default NavBar;

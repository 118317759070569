import React, { useRef, useState } from "react";
import { useNavigate } from "react-router";
import LoadingToast from "../../assets/LoadingToast";
import SuccessToast from "../../assets/SuccessToast";
import ErrorToast from "../../assets/ErrorToast";

const AdminInputFiles = ({ token, setResultData }) => {
  const navigate = useNavigate();
  const fileInput = useRef(null);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    setShowToast(false);
    setShowError(false);

    //validation
    if (!file) {
      setError("Por favor insira um ficheiro");
      setShowToast(false);
      setShowError(true);
      setLoading(false);
      return;
    }

    console.log(file);

    if (file.name.includes(" ")) {
      setError("Nome de ficheiros não podem conter espaços.");
      setShowError(true);
      setLoading(false);
      return;
    }

    //create form body
    let formData = new FormData();
    formData.append("file", file);

    //Submit files
    fetch(`${process.env.REACT_APP_API}/api/tickets/csv`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: formData,
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((parsed) => {
            setShowToast(true);
            setResultData(parsed.reuslts);
          });
        } else if (response.status === 401) {
          navigate("/login");
        } else {
          response.json().then((parsed) => {
            setError(parsed.results);
            setShowError(true);
          });
        }
      })
      .catch((error) => {
        setError(error);
        setShowError(true);
      })
      .finally(() => {
        fileInput.current.value = "";
        setFile(null);
        setLoading(false);
      });
    //document.getElementById("my_modal_1").hideModal(); //close Modal
  };

  return (
    <dialog id="insert_files_modal" className="modal">
      <div className="modal-box">
        <form method="dialog">
          <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
            ✕
          </button>
        </form>
        <form
          onSubmit={handleSubmit}
          className="grid grid-cols-1 gap-4 place-content-center w-full"
        >
          <h3 className="font-bold text-lg">Introduzir Valores</h3>
          <h2 className="text-lg">Ficheiro: (formatos: *.csv)</h2>
          <input
            ref={fileInput}
            type="file"
            accept=".csv"
            className="file-input file-input-bordered justify-self-center w-full"
            onChange={(event) => setFile(event.target.files[0])}
            disabled={loading}
          />
          <button className="btn">Submeter</button>
          {loading && <LoadingToast />}
          {showToast && (
            <SuccessToast
              onClick={(_) => setShowToast(false)}
              text="Ficheiro Submentido."
            />
          )}
          {showError && (
            <ErrorToast onClick={(_) => setShowError(false)} text={error} />
          )}
        </form>
      </div>
    </dialog>
  );
};

export default AdminInputFiles;
